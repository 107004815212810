import React from "react"
import {Link} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import CallButton from "../components/callButton"
import pin from "../images/pin.svg"
// import { slugify } from "../helpers"

import GoogleMapReact from 'google-map-react';


const Marker = ({text, location}) => {
  return (
    <Link to={'/' + location} className="">
      <p className="mx-auto font-bold text-navy-600">{text}</p>
      <img src={pin} alt="Pin" className="mx-auto"/>
    </Link>
  )
};

const LocationsPage = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            addressPrimaryLine
            city
            phone
            state
            zipCode
            name
            id
            phone
            node_locale
            url
            address
            location {
              lon
              lat
            }
          }
        }
      }
    }
  `)

  const defaultProps = {
    center: {
      lat: 34.113615,
      lng: -83.613589
    },
    zoom: 9
  };
  const openingHours = [
    {day: "Monday", openingHour: "9:00 AM", closingHour: "6:00 PM"},
    {day: "Tuesday", openingHour: "10:00 AM", closingHour: "7:00 PM"},
    {day: "Wednesday", openingHour: "9:00 AM", closingHour: "6:00 PM"},
    {day: "Thursday", openingHour: "9:00 AM", closingHour: "6:00 PM"},
    {day: "Friday", openingHour: "7:30 AM", closingHour: "4:30 PM"},
    {day: "Saturday", openingHour: "8:30 AM", closingHour: "2:30 PM"},
    {day: "Sunday", openingHour: "Closed ", closingHour: "Closed"},
  ];
  const locations = data.allContentfulLocation.edges
  return (
    <Layout>
      <SEO title="Locations"/>
      <div className="mx-auto container font-sans ">
        <div className="w-full mb-4 lg:mb-8 lg:pt-8">
          <div className="mx-auto w-full h-64 lg:h-84 rounded">
            {/* Important! Always set the container height explicitly */}
            <GoogleMapReact
              bootstrapURLKeys={{key: "AIzaSyDEGkn9mXgC82rcspgPCajd31s0QclWZSI"}}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              {locations.map(({node: location}) => {
                return <Marker
                  key={location.id}
                  lat={location.location.lat}
                  lng={location.location.lon}
                  text={location.name}
                  location={location.url}
                />
              })}
            </GoogleMapReact>
          </div>
        </div>
        <h2 className="text-center uppercase text-xl mt-2 lg:my-2 text-navy-500 font-thin mb-4 lg:mb-8">U & M Family Eye Care Locations</h2>
        <div className="flex flex-wrap px-4 justify-around">
          {locations.map(({node: location}) => {
            return <div key={location.id} className="mb-8  w-64 ">
              <div className=" text-left text-navy-600 block mb-2 h-auto sm:h-40">
                <div className="font-bold">{location.name}</div>
                <div className="">{' U & M Family Eye Care ' + location.name}</div>
                <div>{location.addressPrimaryLine}</div>
                <div>{location.city + ', '}{location.state + ', '}{location.zipCode}</div>
                <div>{location.phone}</div>
              </div>
              <div className="flex font-sans">
                <Link to={'/' + location.url} aria-label={location.name + ' U & M Family Eye Care'}
                      className="mr-2 px-4 tracking-wide rounded flex cursor-pointer phone items-center justify-center text-sm font-bold bg-navy-25 text-navy-600">
                  Learn more
                </Link>
                <a href={location.phone ? 'tel:' + location.phone : '#'}
                   className=" tracking-wide radial-gradient-green rounded shadow flex  block cursor-pointer phone items-center justify-center ">
                  <svg className="w-8 h-8" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.0592 24.3441C21.0297 28.2167 24.2044 31.3914 28.077 33.362L31.0876 30.3515C31.4707 29.9683 32.0044 29.8588 32.4834 30.0093C34.016 30.5157 35.6581 30.7893 37.3686 30.7893C37.7316 30.7893 38.0796 30.9335 38.3362 31.1901C38.5929 31.4468 38.737 31.7948 38.737 32.1578V36.9472C38.737 37.3102 38.5929 37.6582 38.3362 37.9149C38.0796 38.1715 37.7316 38.3157 37.3686 38.3157C31.1989 38.3157 25.2818 35.8647 20.9191 31.502C16.5564 27.1394 14.1055 21.2223 14.1055 15.0525C14.1055 14.6896 14.2496 14.3415 14.5063 14.0849C14.7629 13.8283 15.111 13.6841 15.4739 13.6841H20.2634C20.6263 13.6841 20.9744 13.8283 21.231 14.0849C21.4876 14.3415 21.6318 14.6896 21.6318 15.0525C21.6318 16.763 21.9055 18.4051 22.4118 19.9378C22.5623 20.4167 22.4528 20.9504 22.0697 21.3336L19.0592 24.3441Z"
                      fill="white"/>
                  </svg>
                  <span className="pr-4 text-white text-sm  text-center">{location.phone}</span>
                </a>
              </div>

            </div>
          })}
        </div>
        <div className="mx-auto max-w-md text-navy-500 px-4 mb-6">
          <h2 className="text-center uppercase text-xl mt-2 lg:my-2 text-navy-500 font-thin mb-4 lg:mb-8">Opening hours</h2>
          <div className="p-2 mb-4">Due to the COVID 19 crisis, we are having to occasionally make changes to our hours of operation. Please call the location you wish to visit to verify hours prior to arrival. Thank you for your understanding.</div>
          <div className="flex justify-end w-full mb-4"><p className="w-32">Day</p><p className="w-32">Open </p> <p className="w-32">Close</p></div>
          {openingHours.map((h,i) =>
            <div className={`flex justify-end w-full mb-2 py-1 ${i === openingHours.length -1 ? '' : 'border-b border-navy-100'}`}><p className="w-32">{h.day}</p><p className="w-32">{h.openingHour}</p> <p className="w-32">{h.closingHour}</p></div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default LocationsPage
